@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  padding: 0;
  font-family: "Lato", sans-serif;
  margin: 0;
}

.App {
  width: 100%;
  overflow: auto;
  color: var(--lightest);
}

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

:root {
  --green: rgb(0,128,0);
  --yellow: rgb(193, 173, 23);
  --darkyellow: rgb(139, 124, 6);
  --darkest: black;
  --darker: rgb(32,32,32);
  --dark: rgb(80,80,80);
  --lightest: rgb(235,235,235);
  --lighter: rgb(200,200,200)
}