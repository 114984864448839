.contact {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: url('../../images/main/image9.webp');
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    padding-bottom: 150px;
}

.contact::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 1; 
}

.contact * {
    position: relative; 
    z-index: 2;
}

.contact_inner {
    display: flex;
    flex-direction: column;
    color: white;
    z-index: 2;
    background-color: rgba(0, 0, 0, .8);
    width: 850px;
    border-radius: 20px;
    box-shadow: 0 0 10px var(--lighter);
    padding-bottom: 30px;
}

.contact_inner_top {
    display: flex;
    flex-direction: column;
    background-color: var(--darkyellow);
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 20px;
}

.form_subtitle {
    font-weight: 300;
}

.contact_inner_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
}


.form_input_outer {
    margin-bottom: 25px;
}

.form_input_prompt {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--darkyellow);
}

.form_input {
    width: 80%;
    height: 28px;
    box-sizing: border-box;
    padding-left: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1.5px solid var(--lightest);
    font-size: 14px;
    color: var(--lightest);
    transition: .3s;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.form_input:focus {
    box-shadow: 0 -.5px 7px var(--lighter);
}

.form_radio {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 15px;
    padding: 10px 0 10px 0;
}

.form_section_title {
    font-weight: 600;
    width: 70%;
    margin: 10px 0 5px 0;
    font-size: 14px;
    padding-bottom: 3px;
    color: var(--darkyellow);
}

.form_radio_item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 13px;
}

.form_radio_item input {
    margin-right: 8px;
    cursor: pointer;
}

.form_textarea {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: 1px solid var(--dark);
    box-shadow: 0 0 10px var(--dark);
    margin-top: 5px;
    border-radius: 10px;
    outline: none;
    height: 100px;
    padding: 10px;
    font-size: 14px;
    color: var(--lightest);
}

.form_submit {
    height: 35px;
    width: 130px;
    background-color: var(--darkyellow);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    color: var(--lightest);
    transition: .3s;
    font-weight: 600;
    font-size: 13px;
}

.status_message {
    margin-top: 5px;
    font-size: 13px;
    color: red;
}

.success_status {
    color: green;
}

.form_submit:hover {
    background-color: var(--yellow);
}

@media (max-width: 730px) {
    .contact_inner {
        width: 500px;
    }
}

@media (max-width: 560px) {
    .contact_inner {
        width: 400px;
    }
}

@media (max-width: 450px) {
    .contact_inner {
        width: calc(100% - 40px);
    }

    .form_input {
        width: 100%;
    } 
    
    .form_section_title {
        width: 100%;
    }

    .form_textarea {
        width: 100%;
    }
}

/* In your Contact.css file */
.loading_spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  