/* Reviews */

.reviews {
    background-color: var(--lightest);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 150px; 
    align-items: center;  
    overflow: hidden;
    position: relative;
    background-color: var(--darkest);
}

.reviews_scroller {
    width: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background: url('../../images/main/image3.webp');
    background-position: center;
    background-size: cover;
}

.reviews_track {
    display: inline-block;
    display: flex;
    animation: scroll 40s linear infinite;
}

.review_item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 35px;
    height: 200px;
    width: 250px;
    border-radius: 10px;
    background-color: var(--lightest);
    color: var(--darkest);
    padding: 15px;
    box-sizing: border-box;
}

.review_span {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-top: 30px solid var(--yellow);
    border-top-right-radius: 10px;
}

.review_name {
    font-size: 17px;
    font-weight: 500;
}

.review_stars {
    font-size: 14px;
    color: var(--green);
    letter-spacing: 2px;
}

.review_desc {
    font-size: 13px;
    letter-spacing: .2px;
    width: 100%;
    margin-top: 10px;
    line-height: 1.3;
    white-space: normal;
}

@keyframes scroll {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-50%); /* Scroll halfway */
    }
    100% {
        transform: translateX(0%); /* Return to the start */
    }
}
  