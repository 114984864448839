/* Why choose */

.why_choose {
    padding-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--darker);
    color: var(--lightest);
    position: relative;
    background: url('../../images/main/image15.webp') center/cover no-repeat;
}

.why_choose::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.why_choose * {
    position: relative;
    z-index: 2;
}

.why_choose_inner {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 1400px;
}

.perk_outer_right {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.perk_outer_left {
    width: 100%;
}

.perk {
    display: flex;
    background-color: var(--darkest);
    box-shadow: 0 0 10px var(--dark);
    height: 230px;
    width: 70%;
    margin-bottom: 80px;
    align-items: center;
    border-radius: 20px;
    color: var(--lightest);
    visibility: hidden;
}

.perk.perk_right_animate, .perk.perk_left_animate {
    visibility: visible;
}

.perk_right_animate {
    opacity: 1;
    animation: perkRight .8s ease-out forwards;
}

@keyframes perkRight {
    from {
        opacity: 0;  /* Start at 0 */
        transform: translateX(100%);
    }
    to {
        opacity: 1;  /* End at 1 */
        transform: translateX(0);
    }
}

.perk_left_animate {
    opacity: 1;
    animation: perkLeft .8s ease-out forwards;
}

@keyframes perkLeft {
    from {
        opacity: 0;  /* Start at 0 */
        transform: translateX(-100%);
    }
    to {
        opacity: 1;  /* End at 1 */
        transform: translateX(0);
    }
}


.perk:hover {
    transform: scale(1.05);
}

.perk_img {    
    height: 140px;
    width: 100px;
    margin: 0 30px;
    border-radius: 5px;
    object-fit: cover; 
    object-position: center;
    box-shadow: 0 0 10px var(--lighter);
}

.perk_span {
    background-color: var(--darkyellow);
    height: 100%;
    width: 20px;
    min-width: 15px;
    flex-shrink: 0;
}

.perk_span_left {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.perk_span_right {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}

.perk_info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.perk_info_left {
    align-items: flex-start;
}

.perk_info_right {
    align-items: flex-end;
    text-align: right;
}

.perk_title {
    margin-bottom: 15px;
}

.perk_desc {
    width: 90%;
    font-size: 13px;
    margin-bottom: 35px;
    line-height: 1.5;
}

.perk_button {
    width: 190px;
    border: none;
    background-color: var(--darkyellow);
    cursor: pointer;
    transition: .3s;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 0;
    color: var(--lightest);
}

.perk_button:hover {
    background-color: var(--yellow);
}

/* Responsive */

@media (max-width: 850px) {
    .perk {
        width: 100%;
    }
  }

  @media (max-width: 750px) {
    .perk {
        height: 320px;
    }
  }

  @media (max-width: 450px) {
    .perk {
        height: 360px;
    }

    .perk_button {
        width: 120px;
    }

    .why_choose_inner {
        width: 92%;
    }

    .perk_img {
        margin: 0 18px;
    }

    .perk_desc {
        margin-bottom: 20px;
    }
  }

