/* Before and After */

.before_and_after_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 100px;
    background-color: var(--darkest);
}

.before_and_after_inner {
    width: 90%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    animation: fadeBaa 1s ease-in-out;
}

@keyframes fadeBaa {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.baa_container {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    border: 1px solid var(--lighter);
}

.baa_container_left {
    border-right: none;
}

.baa_container_right {
    border-left: none;
}

.baa_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: width 0.5s ease, left 0.5s ease, filter 0.5s ease; 
}

.baa_two, .baa_four {
    left: -100%;
    width: 0;
}

.shrink-width {
    width: 0;
    left: 100%;
}

.grow-width {
    left: 0;
    width: 100%;
}

.dim-image {
    filter: brightness(40%); 
}

.fade-out-text {
    opacity: 0; 
}


.view_more_baa {
    margin-top: 40px;
    font-weight: 600;
    height: 40px;
    width: 240px;
    background-color: var(--darkyellow);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    color: var(--lightest);
}

.view_more_baa:hover {
    background-color: var(--yellow);
}