/* Home Open Section */
.home_open {
    position: relative;
    width: 100%;
    height: 80vh;
    background: url('../../images/main/image6.webp') center/cover no-repeat;
    color: var(--lightest);
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_open::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 1;
}

.home_open * {
    position: relative;
    z-index: 2;
}

.home_open_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
    animation: homefadein .7s linear;
}

@keyframes homefadein {
    from {
        opacity: 0;
        transform: translateY(-70px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.home_title {
    font-size: 70px;
    line-height: 60px;
    margin-bottom: 10px;
}

.home_subtitle {
    font-size: 18px;
}

.home_location {
    font-size: 15px;
    font-weight: 600;
    color: var(--lighter);
    margin: 30px 0;
}

.home_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_open_button {
    margin: 0 8px;
    width: 120px;
    height: 45px;
    border: 2px solid var(--darkyellow);
    background-color: var(--darkyellow);
    color: var(--lightest);
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    border-radius: 3px;
    font-weight: 600;
    font-size: 15px;
}

.home_open_button:hover {
    background-color: transparent;
    color: var(--darkyellow);
}

/* Services Section */
.home_services {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--darkest);
    height: 800px;
}


.section_title {
    font-size: 45px;
    margin-top: 100px;
    max-width: 85%;
    line-height: 50px;
    text-align: center;
}

.section_subtitle {
    margin: 0 0 100px 0;
    font-weight: 300;
    font-size: 16px;
    text-align: center;
    max-width: 85%;
}

.home_services_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 90%;
    animation: servicesFadeIn 1.5s ease;
}

@keyframes servicesFadeIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.service_item, .service_item_background {
    width: 50%;
    max-width: 800px;
    height: 380px;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 10px var(--lighter);
    overflow: hidden; 
}

.service_item_background {
    width: 20%;
    opacity: .3;
}

.service_image_blurred {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.carousel_button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 80px;
    padding: 10px;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.carousel_left {
    left: 8%;
}

.carousel_right {
    right: 8%;
}

.service_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.service_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    transition: filter 0.3s ease;  
}

.service_inner_inner {
    background-color: var(--darkyellow);
    color: var(--darkest);
    display: flex;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    width: 100%;
    height: auto; 
    position: relative; 
    padding-bottom: 20px;
    transition: max-height 0.3s ease, padding-bottom 0.3s ease;
}

.service_title {
    margin: 20px 0 15px 20px;
}

.service_desc {
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 13px;
    line-height: 1.5;
    width: 100%;
    flex-grow: 0;
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.service_button {
    position: absolute;
    bottom: 10px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    padding-bottom: 10px;
    left: 20px;
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    opacity: 0; 
    visibility: hidden; 
    transition: opacity 0.3s ease, visibility 0.3s ease; 
    color: var(--darkest);
}

.service_item:hover .service_inner_inner {
    max-height: 260px; 
    padding-bottom: 50px; 
}

.service_item:hover .service_button {
    opacity: 1; 
    visibility: visible;
}

.service_item:hover .service_image {
    filter: brightness(60%);
}

.service_item_hidden {
    display: none;
}

/* Responsive */

@media (max-width: 850px) {
    .service_item {
        width: 70%;
    }

    .service_item_background {
        width: 12%;
    }

    .carousel_left {
        left: 2%;
    }

    .carousel_right {
        right: 2%;
    }
  }

