.collabs {
    display: flex;
    flex-direction: column;
    background-color: var(--darkest);
    width: 100%;
    align-items: center;
}

.collabs_inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
    margin: 35px 0;
    animation: fadeCollabs 1s ease-in-out;
}

@keyframes fadeCollabs {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}   

.collab_image {
    margin: 20px 50px;
    width: 120px;
    object-position: center;
    border-radius: 10px;
}

@media (max-width: 800px) {
    .collab_image {
        margin: 20px 25px;
        width: 90px;
    }
}
